import React from 'react'
import {
  CButton,
  CCol,
  CContainer,
  CRow,
} from '@coreui/react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

const Page401 = () => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [class_page, setClass] = useState('bg-light d-flex flex-row align-items-center');

  useEffect(() => {
    if (pathname === '/401/print') {
      setClass('bg-light min-vh-100 d-flex flex-row align-items-center');
    }else{
      setClass('bg-light d-flex flex-row align-items-center');
    }
  }, [pathname]);

  return (
    <div className={class_page}>
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <div className="clearfix">
              <h1 className="float-start display-3 me-4">401</h1>
              <h4 className="pt-3">Oops! Acesso não autorizado.</h4>
              <p className="text-medium-emphasis float-start">
                Você não possui permissões para acessar esta página
              </p>

              <CButton onClick={() => navigate('/')} >Voltar para a página inicial</CButton>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Page401
